import React from 'react';
import Form, { DisplayField } from 'common/mdc/form';
import { injectIntl, intlShape, defineMessages, FormattedMessage } from 'react-intl';
import DashboardShape from '@sma/components/dashboard/shape';
import Table from 'common/mdc/table';

const messages = defineMessages({
  devicecategory: {
    id: 'dashboard.field.devicecategory',
    defaultMessage: 'Category',
  },
  connect: {
    id: 'dashboard.field.noOfDeviceConnected',
    defaultMessage: 'Connected',
  },
  disconnect: {
    id: 'dashboard.field.noOfDeviceDisconnected',
    defaultMessage: 'Disconnected',
  },
  total: {
    id: 'dashboard.field.total',
    defaultMessage: 'Total',
  },
});

const ConsumerPowerStatusForm = ({ dashboard, intl }) => (
     <div className="dashboard-details-pane">
         <Form style={{ padding: 0 }}>
         <table>
              <tr><th> <FormattedMessage {...messages.devicecategory}/> </th> <th colspan="2"> <FormattedMessage {...messages.connect}/> </th><th colspan="2"> <FormattedMessage {...messages.disconnect}/> </th> <th colspan="2"> <FormattedMessage {...messages.total}/> </th></tr>
                  {dashboard.map((postDetails,index)=>{
             return <tr> <td align="center"> {postDetails.deviceCategory} </td> <td align="center"  colspan="2">  {postDetails.noOfConsumerDeviceConnected} </td> <td align="center"  colspan="2">  {postDetails.noOfConsumerDeviceDisconnected} </td> <td align="center" colspan="2">{postDetails.noOfConsumerDeviceConnected+postDetails.noOfConsumerDeviceDisconnected}</td></tr> }) }
         </table>
</Form>
</div>

);

ConsumerPowerStatusForm.propTypes = {
  dashboard: DashboardShape.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(ConsumerPowerStatusForm);
