import React from 'react';
import Table, { TableRow, TableCell, TableHead } from 'common/mdc/table/index';
import EventType from '@sma/components/device/events/type';
import Datetime from 'common/components/dateTime';
import { defineMessages, FormattedMessage } from 'react-intl';
import { func, shape, arrayOf } from 'prop-types';
import NodeLink from '@sma/components/node/link';

const messages = defineMessages({
  eventTime: {
    id: 'events.table.header.eventTime',
    defaultMessage: 'Event time',
  },
  eventDescription: {
    id: 'events.table.header.eventDescription',
    defaultMessage: 'Description',
  },
  eventType: {
    id: 'events.table.header.eventType',
    defaultMessage: 'Type',
  },
  nodeLink: {
    id: 'events.table.header.nodeLink',
    defaultMessage: ' {nodeId} ',
  },
  node: {
    id: 'events.table.header.node',
    defaultMessage: 'Node',
  },
});

const EventHead = () => (
  <TableHead>
    <TableCell
      flex="0"
      style={{
        minWidth: '180px',
      }}
      alignRight
    ><FormattedMessage {...messages.eventTime} />
    </TableCell>
    <TableCell>
      <FormattedMessage {...messages.eventType} />
    </TableCell>
    <TableCell>
      <FormattedMessage {...messages.eventDescription} />
    </TableCell>
    <TableCell>
      <FormattedMessage {...messages.node} />
    </TableCell>
  </TableHead>
);

EventHead.propTypes = {
  isSorted: func.isRequired,
  toggleSort: func.isRequired,
};

const EventRow = ({ event }) => (
  <TableRow key={event.id}>
    <TableCell>
      {event.eventTime && <Datetime value={event.eventTime} />}
    </TableCell>
    <TableCell>
      {event.type}
    </TableCell>
    <TableCell>
      <EventType type={event.type} />
    </TableCell>
    <TableCell>
      {event.nodeId && (
        <NodeLink nodeId={event.nodeId}>{event.nodeId}</NodeLink>
      )}
    </TableCell>
  </TableRow>
);

EventRow.propTypes = {
  event: shape().isRequired,
};

const Events = ({
  events,
}) => (
  <Table className="event-table">
    {EventHead()}
    {events.map(event => (EventRow({ event })))}
  </Table>
);

Events.propTypes = {
  events: arrayOf(shape()).isRequired,
};

export default Events;
