import * as R from 'ramda';
import React from 'react';
import { arrayOf, func } from 'prop-types';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Link } from 'found';
import AlarmsWarning from '@sma/components/device/alarms/alarmsWarning';

import Table, {
  TableRow,
  TableCell,
  TableActionCell,
  TableHead,
  SELECTION_MULTIPLE,
} from 'common/mdc/table';

import { NodeLink } from '@sma/components/node';
import DeviceMenu from './menu';
import DeviceShape from './shape';

export const messages = defineMessages({
  deviceId: {
    id: 'table.header.deviceId',
    defaultMessage: 'Meter ID',
  },
  vendor: {
    id: 'table.header.vendor',
    defaultMessage: 'Vendor',
  },
  node: {
    id: 'table.header.node',
    defaultMessage: 'Node',
  },
  firmware: {
    id: 'table.header.firmware',
    defaultMessage: 'Firmware',
  },
  hardware: {
    id: 'table.header.hardware',
    defaultMessage: 'Hardware',
  },
  driver: {
    id: 'table.header.driver',
    defaultMessage: 'Driver',
  },
  alarms: {
    id: 'table.header.alarms',
    defaultMessage: 'Alarms',
  },
  actions: {
    id: 'table.header.actions',
    defaultMessage: 'Actions',
  },
});

export const COLUMN_METER_ID = Symbol('deviceId');
export const COLUMN_VENDOR = Symbol('vendor');
export const COLUMN_NODE_ID = Symbol('nodeId');
export const COLUMN_FIRMWARE = Symbol('firmwareVersion');
export const COLUMN_HARDWARE = Symbol('hardwareVersion');
export const COLUMN_DRIVER = Symbol('driver');
export const COLUMN_ALARMS = Symbol('alarms');
export const COLUMN_ACTIONS = Symbol('actions');

export const columns = [
  COLUMN_METER_ID,
  COLUMN_VENDOR,
  COLUMN_NODE_ID,
  COLUMN_FIRMWARE,
  COLUMN_HARDWARE,
  COLUMN_DRIVER,
  COLUMN_ACTIONS,
  COLUMN_ALARMS,
];

export const deviceHead = ({ isSorted, toggleSort, displayColumns = columns, intl, ...props }) => {
  const hidden = R.complement(R.flip(R.contains)(displayColumns));

  return (
    <TableHead {...props}>
      <TableCell
        sortable
        columnId={COLUMN_METER_ID}
        title={intl.formatMessage(messages.deviceId)}
        onClick={() => toggleSort('deviceId')}
        sorted={isSorted('deviceId')}
        style={{
          minWidth: '180px',
        }}
        flex="2"
      ><FormattedMessage {...messages.deviceId} />
      </TableCell>
      <TableCell
        sortable
        columnId={COLUMN_VENDOR}
        title={intl.formatMessage(messages.vendor)}
        hidden={hidden(COLUMN_VENDOR)}
        onClick={() => toggleSort('vendor')}
        sorted={isSorted('vendor')}
        flex="2"
      ><FormattedMessage {...messages.vendor} />
      </TableCell>
      <TableCell
        sortable
        columnId={COLUMN_NODE_ID}
        title={intl.formatMessage(messages.node)}
        hidden={hidden(COLUMN_NODE_ID)}
        flex="2"
        onClick={() => toggleSort('nodeId')}
        sorted={isSorted('nodeId')}
      ><FormattedMessage {...messages.node} />
      </TableCell>
      <TableCell
        sortable
        columnId={COLUMN_FIRMWARE}
        title={intl.formatMessage(messages.firmware)}
        hidden={hidden(COLUMN_FIRMWARE)}
        flex="1"
        onClick={() => toggleSort('firmwareVersion')}
        sorted={isSorted('firmwareVersion')}
      ><FormattedMessage {...messages.firmware} />
      </TableCell>
      <TableCell
        sortable
        columnId={COLUMN_HARDWARE}
        title={intl.formatMessage(messages.hardware)}
        hidden={hidden(COLUMN_HARDWARE)}
        onClick={() => toggleSort('hardwareVersion')}
        sorted={isSorted('hardwareVersion')}
        flex="3"
      ><FormattedMessage {...messages.hardware} />
      </TableCell>
      <TableCell
        sortable
        columnId={COLUMN_DRIVER}
        title={intl.formatMessage(messages.driver)}
        hidden={hidden(COLUMN_DRIVER)}
        onClick={() => toggleSort('driver')}
        sorted={isSorted('driver')}
        flex="1"
      ><FormattedMessage {...messages.driver} />
      </TableCell>
      <TableCell
        columnId={COLUMN_ALARMS}
        title={intl.formatMessage(messages.alarms)}
        flex="1"
        alignRight
      ><FormattedMessage {...messages.alarms} />
      </TableCell>
      <TableCell
        actions
        columnId={COLUMN_ACTIONS}
        title={intl.formatMessage(messages.actions)}
        hidden={hidden(COLUMN_ACTIONS)}
      ><FormattedMessage {...messages.actions} />
      </TableCell>
    </TableHead>
  );
};

export const deviceRow = props => device => (
  <TableRow
    key={device.deviceId}
    {...props}
  >
    <TableCell
      columnId={COLUMN_METER_ID}
      style={{ direction: 'rtl' }}
      title={device.deviceId}
    >
      {device.alarms && <AlarmsWarning alarms={device.alarms} />}
      <Link to={`/devices/${encodeURI(device.deviceId)}`}>
        {device.deviceId}
      </Link>
    </TableCell>
    <TableCell columnId={COLUMN_VENDOR}>{device.vendor}</TableCell>
    <TableCell
      columnId={COLUMN_NODE_ID}
      title={device.nodeId}
      style={{ direction: 'rtl' }}
    >
      {device.nodeId && (
        <NodeLink nodeId={device.nodeId} style={{ display: 'flex' }}>
          {device.nodeId}
        </NodeLink>
      )}
    </TableCell>
    <TableCell
      columnId={COLUMN_FIRMWARE}
      style={{ direction: 'rtl' }}
    >
      {device.firmwareVersion}
    </TableCell>
    <TableCell columnId={COLUMN_HARDWARE}>{device.hardwareVersion}</TableCell>
    <TableCell columnId={COLUMN_DRIVER}>{device.driver}</TableCell>
    <TableCell columnId={COLUMN_ALARMS}>{device.alarms && device.alarms.length}</TableCell>
    <TableActionCell columnId={COLUMN_ACTIONS}>
      <DeviceMenu device={device} />
    </TableActionCell>
  </TableRow>
);

const DeviceTable = ({
  devices,
  isSorted,
  toggleSort,
  isSelected,
  onSelectionChange,
  displayColumns,
  intl,
  ...props
}) => (
  <Table
    className="meters-table"
    {...props}
  >
    {deviceHead({
      isSorted,
      toggleSort,
      displayColumns,
      intl,
    })}
    {devices.map(device => deviceRow({
      selected: isSelected(device),
      onClick: () => onSelectionChange(device),
    })(device))}
  </Table>
);

export const DeviceSelectionTable = injectIntl(({
  displayColumns,
  devices,
  isSorted,
  toggleSort,
  isSelected,
  onSelect,
  isAllSelected,
  onSelectionChange,
  intl,
  ...props
}) => (
  <Table
    className="meters-table"
    selectable
    selectionType={SELECTION_MULTIPLE}
    {...props}
  >
    {deviceHead({
      intl,
      displayColumns,
      toggleSort,
      isSorted,
      onSelectionChange,
      checked: isAllSelected,
    })}
    {devices.map(device => deviceRow({
      checked: isSelected(device),
      onSelect: () => onSelect(device),
      onClick: () => onSelect(device),
    })(device))}
  </Table>
));

DeviceTable.propTypes = {
  devices: arrayOf(DeviceShape).isRequired,
  toggleSort: func.isRequired,
  isSorted: func.isRequired,
  isSelected: func,
  onSelectionChange: func,
  intl: intlShape.isRequired,
};

DeviceTable.defaultProps = {
  isSelected: () => false,
  onSelectionChange: () => {},
};

export default injectIntl(DeviceTable);
