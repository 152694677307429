import { shape, string, arrayOf, number } from 'prop-types';

export default shape({
deviceCategory: string.isRequired,
noOfDeviceOnline: number.isRequired,
noOfDeviceOffline: number.isRequired,
noOfDeviceLoggedIn: number.isRequired,
noOfDeviceLoggedOut: number.isRequired,
noOfConsumerDeviceConnected: number.isRequired,
noOfConsumerDeviceDisconnected: number.isRequired,
noOfMeterPowerSupplyOn: number.isRequired,
noOfMeterPowerSupplyOff: number.isRequired,
});
