import S, { maybeShape } from 'common/service/sanctuary';
import React from 'react';
import { connect } from 'react-redux';
import Icon from 'common/mdc/icon';
import { string, node, shape } from 'prop-types';

import {
  selectors,
} from 'common/store/server/links';

const NodeLink = ({ nodeId, children, maybeLink, style }) => S.maybe(
  <span>{nodeId}</span>,
  link => (
    <a
      onClick={(e) => { e.stopPropagation(); }}
      href={`${link}/nodes/${nodeId}`}
      className="mdc-list-item nodeLink"
      style={style}
    >
      <span className="description">{children}</span>
      &nbsp;
      <Icon className="mdc-list-item__graphic" aria-hidden="true">open_in_new</Icon>
    </a>
  ),
  maybeLink,
);

NodeLink.propTypes = {
  nodeId: string.isRequired,
  children: node.isRequired,
  maybeLink: maybeShape,
  style: shape(),
};

NodeLink.defaultProps = {
  style: {},
};

export default connect(
  state => ({
    maybeLink: selectors.getLink(state)('nms'),
  }),
)(NodeLink);
