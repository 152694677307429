import {
  createStore,
  applyMiddleware,
  compose,
} from 'redux';

import {
  Actions as FarceActions,
  BrowserProtocol,
  createHistoryEnhancer,
  queryMiddleware,
  createBasenameMiddleware,
} from 'farce';

import { Matcher, createMatchEnhancer } from 'found';
import { createEpicMiddleware } from 'redux-observable';

import makeRouteConfig from '@sma/router/config';

import reducer from './reducer';
import epic from './epic';

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (intl, services) => {
  const basename = services.configService.getConfig('endpoint', 'basename');
  const matcher = new Matcher(makeRouteConfig(intl));
  const initial = {};
  let basenameMiddleware = null;

  if (basename && basename !== '/') {
    basenameMiddleware = createBasenameMiddleware({
      basename,
    });
  }

  const epicMiddleware = createEpicMiddleware({
    dependencies: {
      ...services,
      intl,
    },
  });

  const store = createStore(reducer,
    initial,
    composeEnhancers(
      applyMiddleware(epicMiddleware),
      createHistoryEnhancer({
        protocol: new BrowserProtocol(),
        middlewares: [
          queryMiddleware,
          basenameMiddleware,
        ].filter(Boolean),
      }),
      createMatchEnhancer(matcher),
    ));

  store.dispatch(FarceActions.init());
  epicMiddleware.run(epic);
  return store;
};

export default configureStore;
