import React from 'react';
import { func } from 'prop-types';
import { connect } from 'react-redux';
import { FormattedMessage, defineMessages } from 'react-intl';
import Menu, { MenuItem } from 'common/mdc/menu';
import { logout } from 'common/store/auth/actions';

const messages = defineMessages({
  // viewProfile: {
  //   id: 'header.profile.viewProfile',
  //   defaultMessage: 'View profile',
  // },
  logout: {
    id: 'header.profile.logout',
    defaultMessage: 'Logout',
  },
});

const ProfileMenu = ({ logout, ...props }) => (
  <Menu {...props}>
    {/* <MenuItem key="profile">
      <FormattedMessage {...messages.viewProfile} />
    </MenuItem> */}
    <MenuItem key="logout" onClick={logout}>
      <FormattedMessage {...messages.logout} />
    </MenuItem>
  </Menu>
);

ProfileMenu.propTypes = {
  logout: func.isRequired,
};

export default connect(
  null,
  {
    logout,
  },
)(ProfileMenu);
