import { combineEpics } from 'redux-observable';
import { epic as auth } from 'common/store/auth';
import { epic as server } from 'common/store/server';

import devices from './device/epic';
import { epic as groups } from './group';
import meteringProfiles from './meteringProfile/meteringProfiles/epic';

export default combineEpics(
  auth,
  devices,
  server,
  groups,
  meteringProfiles,
);
