import React from 'react';
import Form, { DisplayField } from 'common/mdc/form';
import { injectIntl, intlShape, defineMessages, FormattedMessage } from 'react-intl';
import DashboardShape from '@sma/components/dashboard/shape';
//import Table from 'common/mdc/table';
import Table, { TableRow, TableCell, TableHead } from 'common/mdc/table';

const messages = defineMessages({
  devicecategory: {
    id: 'dashboard.field.devicecategory',
    defaultMessage: 'Category',
  },
  powerSupplyOn: {
    id: 'dashboard.field.noOfMeterPowerSupplyOn',
    defaultMessage: 'Powered-on',
  },
  powerSupplyOff: {
    id: 'dashboard.field.noOfMeterPowerSupplyOff',
    defaultMessage: 'Powered-off',
  },
  total: {
    id: 'dashboard.field.total',
    defaultMessage: 'Total',
  },
});

const MeterPowerStatusForm = ({ dashboard, intl }) => (
     <div className="dashboard-details-pane">
         <Form style={{ padding: 4 }}><table>
         <tr><th><FormattedMessage {...messages.devicecategory} /></th><th colspan="4"><FormattedMessage {...messages.powerSupplyOn} /> </th> <th colspan="4"> <FormattedMessage {...messages.powerSupplyOff} /></th><th colspan="4"> <FormattedMessage {...messages.total} /></th></tr>
         {dashboard.map((postDetails,index)=>{
             return <tr> <td align="center"> {postDetails.deviceCategory} </td> <td align="center"  colspan="4">  {postDetails.noOfMeterPowerSupplyOn} </td> <td align="center"  colspan="4">  {postDetails.noOfMeterPowerSupplyOff} </td> <td align="center"  colspan="4"> {postDetails.noOfMeterPowerSupplyOn+postDetails.noOfMeterPowerSupplyOff} </td></tr> }) }
         </table>
         </Form>
</div>

);

MeterPowerStatusForm.propTypes = {
  dashboard: DashboardShape.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(MeterPowerStatusForm);
