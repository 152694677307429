import S from 'common/service/sanctuary';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { arrayOf, shape } from 'prop-types';
import Table, { TableHead, TableCell, TableRow } from 'common/mdc/table';
import SampleValue from '@sma/components/samples/sampleValue';
import DateTime from 'common/components/dateTime';
import classnames from 'classnames';
import ScrollContainer from 'common/mdc/scrollbar/container';

import RegisterName from '../name';
import registerShape from '../shape';

const messages = defineMessages({
  datetime: {
    id: 'smas.devices.actions.on-demand-history.datetime',
    defaultMessage: 'Sample datetime',
  },
});

const COLUMN_SAMPLE_TIME = 'SAMPLE_TIME';

const OnDemandHistoryTable = ({ registers, samples }) => (
  <ScrollContainer useRef="tableRef" settings={{ suppressScrollY: true }}>
    <Table className={classnames('samplesTable', 'on-demand-history-table')}>
      <TableHead>
        <TableCell columnId={COLUMN_SAMPLE_TIME} flex="2" alignRight>
          <FormattedMessage {...messages.datetime} />
        </TableCell>
        {registers.map((register, index) => (
          <TableCell
            key={index}
            columnId={index}
            flex="2"
            alignRight
          >
            <RegisterName register={register} />
          </TableCell>
        ))}
      </TableHead>
      {samples.map(sample => (
        <TableRow key={sample.sampleTime}>
          <TableCell columnId={COLUMN_SAMPLE_TIME}>
            <DateTime value={sample.sampleTime} title seconds />
          </TableCell>
          {registers.map((register, index) => (
            <TableCell key={index} columnId={index}>
              {S.maybeToNullable(S.map(
                foundSample => (
                  <SampleValue sampleValue={foundSample.rawValue} stringValue={foundSample.value} />
                ),
                S.head(sample.registerValues.filter(
                  r => r.obisCode === register.obisCode,
                )),
              ))}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </Table>
  </ScrollContainer>
);

OnDemandHistoryTable.propTypes = {
  registers: arrayOf(registerShape).isRequired,
  samples: arrayOf(shape()).isRequired,
};

export default OnDemandHistoryTable;
