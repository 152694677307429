import React from 'react';
import Form, { DisplayField } from 'common/mdc/form';
import { injectIntl, intlShape, defineMessages, FormattedMessage } from 'react-intl';
import DashboardShape from '@sma/components/dashboard/shape';
import Table from 'common/mdc/table';

const messages = defineMessages({
  devicecategory: {
    id: 'dashboard.field.devicecategory',
    defaultMessage: 'Category',
  },
  login: {
    id: 'dashboard.field.noOfDeviceLoggedIn',
    defaultMessage: 'Logged-in',
  },
  logout: {
    id: 'dashboard.field.noOfDeviceLoggedOut',
    defaultMessage: 'Logged-out',
  },
  total: {
    id: 'dashboard.field.total',
    defaultMessage: 'Total',
  },
});

const CommStatus = ({ dashboard, intl }) => (
        <div className="dashboard-details-pane">
          <Form style={{ padding: 0 }}>
           <table>
            
            <tr><th> <FormattedMessage {...messages.devicecategory}/> </th> <th colspan="2"> <FormattedMessage {...messages.login}/> </th><th colspan="2"> <FormattedMessage {...messages.logout}/> </th> <th colspan="2"> <FormattedMessage {...messages.total}/> </th></tr>
             {dashboard.map((postDetails,index)=>{
            return <tr> <td align="center"> {postDetails.deviceCategory} </td><td align="center" colspan="2">  {postDetails.noOfDeviceLoggedIn} </td><td align="center" colspan="2"> {postDetails.noOfDeviceLoggedOut} </td><td align="center">{postDetails.noOfDeviceLoggedIn+postDetails.noOfDeviceLoggedOut}</td> </tr> }) }
  </table>
  </Form>
</div>

);

CommStatus.propTypes = {
  dashboard: DashboardShape.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(CommStatus);
