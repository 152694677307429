import React from 'react';
import Form, { DisplayField } from 'common/mdc/form';
import { injectIntl, intlShape, defineMessages, FormattedMessage } from 'react-intl';
import DashboardShape from '@sma/components/dashboard/shape';
import Table from 'common/mdc/table';

const messages = defineMessages({
  devicecategory: {
    id: 'dashboard.field.devicecategory',
    defaultMessage: 'Category',
  },
  online: {
   id: 'dashboard.field.noOfDeviceOnline',
    defaultMessage: 'OnLine',
  },
  offLine: {
    id: 'dashboard.field.noOfDeviceOffline',
    defaultMessage: 'OffLine',
  },
  total: {
    id: 'dashboard.field.total',
    defaultMessage: 'Total',
  },
});

const DataAvailForm = ({ dashboard, intl }) => (
  <div className="dashboard-details-pane">
    <Form style={{ padding: 0 }}>
    <table>
    <tr><th> <FormattedMessage {...messages.devicecategory}/> </th> <th colspan="2"> <FormattedMessage {...messages.online}/> </th><th colspan="2"> <FormattedMessage {...messages.offLine}/> </th> <th colspan="2"> <FormattedMessage {...messages.total}/> </th></tr>
    {dashboard.map((postDetails,index)=>{
      return <tr> <td align="center"> {postDetails.deviceCategory} </td> <td align="center"  colspan="2">  {postDetails.noOfDeviceOnline}  </td> <td align="center"  colspan="2"> {postDetails.noOfDeviceOffline}</td> <td align="center"  colspan="2"> {postDetails.noOfDeviceOnline+postDetails.noOfDeviceOffline} </td></tr> })
    }
    </table>
    </Form>
  </div>
  
);

DataAvailForm.propTypes = {
  dashboard: DashboardShape.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(DataAvailForm);
