import React from 'react';
import { shape } from 'prop-types';
import AlarmsWarning from '@sma/components/device/alarms/alarmsWarning';

const DeviceHeader = ({ device }) => (
  <div className="device-title">
    <span>{device.deviceId}</span>
    {device.alarms && <AlarmsWarning alarms={device.alarms} />}
  </div>
);

DeviceHeader.propTypes = {
  device: shape().isRequired,
};

export default DeviceHeader;
