import * as search from 'common/store/search/advanced/actions';

export const NAMESPACE = 'DEVICE/SEARCH';

const searchActions = search.factory(NAMESPACE);

export const {
  LOAD_CRITERIA,
  APPLY_CRITERIA,
  REMOVE_CRITERIA,
  CLEAR,
} = searchActions.TYPES;

export const {
  toggleExpand,
  addCriteria,
  removeCriteria,
  loadCriteria,
  applyCriteria,
  addConstraint,
  removeConstraint,
  clearConstraints,
  setOperator,
  setValue,
  clear,
} = searchActions;
