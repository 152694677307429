import * as R from 'ramda';
import React from 'react';
import { arrayOf, func, shape, number, oneOfType, string } from 'prop-types';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  intlShape,
} from 'react-intl';
import DateTime from 'common/components/dateTime';
import ScrollContainer from 'common/mdc/scrollbar/container';

import Table, {
  TableRow,
  TableCell,
  TableHead,
} from 'common/mdc/table';

import {
  COLUMN_SAMPLE_ID,
  COLUMN_SAMPLE_TIME,
  COLUMN_REGISTERED_TIME,
} from '@sma/service/meteringProfileSamples';

import RegisterName from '@sma/components/device/onDemandReading/name';
import registerShape from '@sma/components/device/onDemandReading/shape';
import meteringProfileShape from '@sma/components/device/meteringProfile/shape';
import { compareRegisters } from '@sma/store/device/meteringProfile/selectors';
import RegisterSampleValue from './sampleValue';

export const messages = defineMessages({
  sampleTime: {
    id: 'sample.header.sampleTime',
    defaultMessage: 'Sample time',
  },
  registeredTime: {
    id: 'sample.header.registeredTime',
    defaultMessage: 'Registered time',
  },
  sampleId: {
    id: 'sample.header.sampleId',
    defaultMessage: 'Sample ID',
  },
});

export const sampleHead = ({
  toggleSort,
  isSorted,
  registers,
  intl,
  ...props
}) => (
  <TableHead {...props}>
     <TableCell
      sortable
      columnId={COLUMN_SAMPLE_ID}
      onClick={() => toggleSort(COLUMN_SAMPLE_ID)}
      sorted={isSorted(COLUMN_SAMPLE_ID)}
      title={intl.formatMessage(messages.sampleId)}
      style={{ minWidth: '190px' }}
     ><FormattedMessage {...messages.sampleId} />
    </TableCell>
    <TableCell
      sortable
      columnId={COLUMN_SAMPLE_TIME}
      onClick={() => toggleSort(COLUMN_SAMPLE_TIME)}
      sorted={isSorted(COLUMN_SAMPLE_TIME)}
      title={intl.formatMessage(messages.sampleTime)}
      style={{ minWidth: '190px' }}
      alignRight
    ><FormattedMessage {...messages.sampleTime} />
    </TableCell>
    <TableCell
      sortable
      columnId={COLUMN_REGISTERED_TIME}
      onClick={() => toggleSort(COLUMN_REGISTERED_TIME)}
      sorted={isSorted(COLUMN_REGISTERED_TIME)}
      title={intl.formatMessage(messages.registeredTime)}
      style={{ minWidth: '180px' }}
      alignRight
    ><FormattedMessage {...messages.registeredTime} />
    </TableCell>
    {registers.map(
      (column, index) => (
        <TableCell
          key={index}
          columnId={index}
          flex="2"
          alignRight
        >
          <RegisterName register={column} title />
        </TableCell>
      ),
    )}
  </TableHead>
);

sampleHead.propTypes = {
  registers: arrayOf(registerShape).isRequired,
  isSorted: func.isRequired,
  toggleSort: func.isRequired,
  intl: intlShape.isRequired,
};

export const sampleRow = ({ key, meteringProfile, registers, ...props }) => (sample) => {
  const samples = (sample && sample.values) || [];
  const getRegisterSample = register => R.find(compareRegisters(register), samples);

  return (
    <TableRow
      key={key}
      {...props}
    >
      <TableCell columnId={COLUMN_SAMPLE_ID}>
      {sample.sampleId}
      </TableCell>
      <TableCell columnId={COLUMN_SAMPLE_TIME}>
        {sample.sampleTime && (
          <DateTime value={sample.sampleTime} seconds />
        )}
      </TableCell>
      <TableCell columnId={COLUMN_REGISTERED_TIME}>
        {sample.registeredTime && (
          <DateTime value={sample.registeredTime} seconds />
        )}
      </TableCell>
      {registers.map((register, index) => {
        const registerSample = getRegisterSample(register);

        return (
          <TableCell key={index} columnId={index}>
            {registerSample && (
              registerSample.rawValue ?
                <RegisterSampleValue
                  sampleValue={registerSample.rawValue}
                  stringValue={registerSample.value}
                /> :
                registerSample.value
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

const MeteringProfileSamplesTable = ({
  samples,
  meteringProfile,
  isSorted,
  toggleSort,
  registers,
  intl,
  ...props
}) => (
  <ScrollContainer useRef="tableRef" settings={{ suppressScrollY: true }}>
    <Table className="samplesTable" {...props}>
      {sampleHead({
        isSorted,
        toggleSort,
        registers,
        intl,
      })}
      {samples.map((sample, key) => sampleRow({ key, meteringProfile, registers })(sample))}
    </Table>
  </ScrollContainer>
);

MeteringProfileSamplesTable.propTypes = {
  samples: arrayOf(shape({
    id: number,
    value: oneOfType([string, number]),
  })).isRequired,
  registers: arrayOf(registerShape).isRequired,
  meteringProfile: meteringProfileShape.isRequired,
  isSorted: func.isRequired,
  toggleSort: func.isRequired,
  intl: intlShape.isRequired,
};

export default injectIntl(MeteringProfileSamplesTable);
