import * as R from 'ramda';
import {
  collection,
  composeReducers,
} from 'common/store/reducer';

import {
  COLUMN_SAMPLE_TIME,
} from '@sma/service/meteringProfileSamples';

import {
  LOAD_ALL, LOAD_ALL_SUCCESS, LOAD_ALL_ERROR,
  UPDATE_ALL, TOGGLE_SORT, PAGINATION_CHANGE,
  CLEAR_ALL, SELECT_PROFILE,
  SELECT_REGISTER,
  SET_REGISTERS,
} from './actions';

const initial = {
  ...collection.defaultState,
  ...collection.defaultSort(COLUMN_SAMPLE_TIME),
  registers: [],
  meteringProfileId: null,
  from: undefined,
  to: undefined,
};

const setFilters = (state, { from, to }) => ({
  ...state,
  from,
  to,
});

const loadAll = composeReducers(
  setFilters,
  collection.load,
);

const clear = () => initial;

const setMeteringProfile = (state, { meteringProfileId }) => ({
  ...state,
  meteringProfileId,
});

const clearRegisters = state => ({
  ...state,
  registers: [],
});

const onSelectRegister = ({ registers, ...state }, { register }) => {
  const registers$ = R.find(R.equals(register), registers)
    ? R.reject(R.equals(register), registers)
    : R.append(register, registers);

  return {
    ...state,
    registers: registers$,
  };
};

const setRegisters = (state, { registers }) => ({
  ...state,
  registers,
});

export default (state = initial, action) => {
  const reducer = {
    [LOAD_ALL]: composeReducers(loadAll, setMeteringProfile),
    [LOAD_ALL_SUCCESS]: collection.loadSuccess,
    [LOAD_ALL_ERROR]: collection.loadError,
    [TOGGLE_SORT]: collection.toggleSort,
    [UPDATE_ALL]: collection.update,
    [PAGINATION_CHANGE]: collection.paginationChange,
    [CLEAR_ALL]: clear,
    [SELECT_PROFILE]: composeReducers(clear, setMeteringProfile),
    [SELECT_REGISTER]: onSelectRegister,
    [SET_REGISTERS]: setRegisters,
  }[action.type];

  return reducer ? reducer(state, action) : state;
};
