import React from 'react';
import Table, { TableRow, TableCell, TableHead } from 'common/mdc/table';
import { SORT_ALARM_TIME, SORT_REGISTERED_TIME } from '@sma/service/alarms';
import Datetime from 'common/components/dateTime';
import { toString } from '@sma/components/device/alarms/type';
import { defineMessages, FormattedMessage } from 'react-intl';
import { func, shape, arrayOf } from 'prop-types';

const messages = defineMessages({
  alarmTime: {
    id: 'alarms.table.header.alarmTime',
    defaultMessage: 'Alarm time',
  },
  registeredTime: {
    id: 'alarms.table.header.registeredTime',
    defaultMessage: 'Registered time',
  },
  alarms: {
    id: 'alarms.table.header.alarms',
    defaultMessage: 'Alarms',
  },
});

const AlarmHead = () => (
  <TableHead>
    <TableCell
      columnId={SORT_ALARM_TIME}
      flex="0"
      style={{
        minWidth: '180px',
      }}
      alignRight
    ><FormattedMessage {...messages.alarmTime} />
    </TableCell>
    <TableCell
      columnId={SORT_REGISTERED_TIME}
      flex="0"
      style={{
        minWidth: '180px',
      }}
      alignRight
    ><FormattedMessage {...messages.registeredTime} />
    </TableCell>
    <TableCell>
      <FormattedMessage {...messages.alarms} />
    </TableCell>
  </TableHead>
);

AlarmHead.propTypes = {
  isSorted: func.isRequired,
  toggleSort: func.isRequired,
};

const AlarmRow = ({ alarm }) => (
  <TableRow key={alarm.id}>
    <TableCell columnId={SORT_ALARM_TIME}>
      {alarm.alarmTime && <Datetime value={alarm.alarmTime} />}
    </TableCell>
    <TableCell columnId={SORT_REGISTERED_TIME}>
      {alarm.registeredTime && <Datetime value={alarm.registeredTime} />}
    </TableCell>
    <TableCell>
      {alarm.alarms.map(toString).join(', ')}
    </TableCell>
  </TableRow>
);

AlarmRow.propTypes = {
  alarm: shape().isRequired,
};

const Alarms = ({
  alarms,
}) => (
  <Table className="alarm-table">
    {AlarmHead()}
    {alarms.map(alarm => (AlarmRow({ alarm })))}
  </Table>
);

Alarms.propTypes = {
  alarms: arrayOf(shape()).isRequired,
};

export default Alarms;
