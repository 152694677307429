import ApiService from 'common/service/api';
import { map } from 'rxjs/operators';

export default class DashboardService extends ApiService {

  constructor(config) {
    super(config);

    this.baseURL = `${this.baseURL}/dashboard`;
  }

  getDashboard(){
     return this.fetchCollection('', {
      });
  }

  getAll(props = {}) {
    return this.fetchCollection('', {
      ...props,
      limit: null,
      offset: null,
    });
  }

}
