import React from 'react';
import Icon from 'common/mdc/icon';
import { FormattedMessage, defineMessages } from 'react-intl';
import { string } from 'prop-types';
import {
  CONNECTED_ACTIVE,
  CONNECTED_INACTIVE,
  DISCONNECTED,
} from '@sma/service/device';

const messages = defineMessages({
  statusDisconnected: {
    id: 'smas.devices.actions.relay-connect-disconnect.statusDisconnected',
    defaultMessage: 'Meter {deviceId} is {icon} disconnected',
  },
  statusConnectedActive: {
    id: 'smas.devices.actions.relay-connect-disconnect.statusConnectedActive',
    defaultMessage: 'Meter {deviceId} is {icon} connected and switched on',
  },
  statusConnectedInactive: {
    id: 'smas.devices.actions.relay-connect-disconnect.statusConnectedInactive',
    defaultMessage: 'Meter {deviceId} is {icon} connected and switched off',
  },
});

const DeviceStatus = ({ status, deviceId }) => {
  const [
    icon,
    className,
    message,
  ] = ({
    [CONNECTED_ACTIVE]: ['check', 'relay-connected-active', messages.statusConnectedActive],
    [CONNECTED_INACTIVE]: ['check', 'relay-connected-inactive', messages.statusConnectedInactive],
    [DISCONNECTED]: ['close', 'relay-disconnected', messages.statusDisconnected],
  })[status];

  return (
    <FormattedMessage
      {...message}
      values={{
        deviceId,
        icon: (
          <Icon className={className}>{icon}</Icon>
        ),
      }}
    />
  );
};

DeviceStatus.propTypes = ({
  status: string.isRequired,
  deviceId: string.isRequired,
});

export default DeviceStatus;
