import React from 'react';
import { defineMessages, FormattedMessage, intlShape, injectIntl } from 'react-intl';
import Grid, { GridRow, GridCell } from 'common/mdc/grid';
import Main from 'common/mdc/layout';
import DataAvailForm from '@sma/components/dashboard/dataAvailForm';
import CommStatus from '@sma/components/dashboard/commStatus';
import ConsumerPowerStatusForm from '@sma/components/dashboard/consumerPowerStatus';
import MeterPowerStatusForm from '@sma/components/dashboard/meterPowerStatus';
import { Widget } from 'common/mdc/card';

const messages = defineMessages({
  title: {
    id: 'title.dashboard',
    defaultMessage: 'Dashboard',
  },
  serverVersion: {
    id: 'widgets.server.version',
    defaultMessage: 'Server version',
  },
  devices: {
    id: 'widgets.devices',
    defaultMessage: 'Meters',
  },
  monitoring: {
    id: 'widgets.monitoring',
    defaultMessage: 'Monitoring',
  },
  servers: {
    id: 'widgets.servers',
    defaultMessage: 'Servers',
  },
  meteringProfiles: {
    id: 'widgets.meteringProfiles',
    defaultMessage: 'Metering profiles',
  },
  devicecategory: {
    id: 'dashboard.devicecategory',
    defaultMessage: 'Category',
  },
  deviceStatus: {
    id: 'widgets.deviceStatus',
    defaultMessage: 'Meters Status',
 },
 consumerStatus: {
    id: 'widgets.consumerStatus',
    defaultMessage: 'Meters Consumer Status',
 },
 meterSupplyStatus: {
    id: 'widgets.meterSupplyStatus',
    defaultMessage: 'Meters Supply Status',
 },
});

const Dashboard = ({ data:{ dashboard }, intl }) => (
  <Main
    title={<FormattedMessage {...messages.title} />}
  >
    <Grid className="main-dashboard">
    <GridRow>
    <GridCell>
      <Widget title={intl.formatMessage(messages.devices)} >
        <DataAvailForm dashboard={dashboard.data} />
      </Widget>
    </GridCell>

    <GridCell>
    <Widget title={intl.formatMessage(messages.deviceStatus)} >
      <CommStatus dashboard={dashboard.data} />
    </Widget>
  </GridCell>

  <GridCell>
      <Widget
        title={intl.formatMessage(messages.consumerStatus)}
      >
        <ConsumerPowerStatusForm dashboard={dashboard.data} />
      </Widget>
   </GridCell>

   <GridCell>
   <Widget
     title={intl.formatMessage(messages.meterSupplyStatus)}
   >
     <MeterPowerStatusForm dashboard={dashboard.data} />
   </Widget>
</GridCell>
    </GridRow>
    </Grid>

  </Main>
);

Dashboard.propTypes = {
     intl: intlShape.isRequired,
};

export default injectIntl(Dashboard);