import S from 'common/service/sanctuary';
import React from 'react';
import { connect } from 'react-redux';
import AdvancedSearch, { DisplayCriteria } from 'common/components/search/advanced';
import { defineMessages, FormattedMessage } from 'react-intl';
import { STATUS_ONLINE, STATUS_OFFLINE, messages as statusMessages } from 'common/components/search/status';
import { types } from 'common/store/search/advanced/criteria';

import {
  selectors,
  actions,
} from '@sma/store/device/search';

import {
  PLAIN,
} from 'common/store/search/advanced/criteria/types';

export const METER_ID = 'deviceId';
export const VENDOR = 'vendor';
export const NODE_ID = 'nodeId';
export const FIRMVARE_VERSION = 'firmwareVersion';
export const HARDWARE_VERSION = 'hardwareVersion';
export const DRIVER = 'driver';
export const GATEWAY = 'gateway';
export const STATUS = 'status';

export const messages = defineMessages({
  meterId: {
    id: 'meter.search.advanced.meterId',
    defaultMessage: 'Meter ID',
  },
  vendor: {
    id: 'meter.search.advanced.vendor',
    defaultMessage: 'Vendor',
  },
  nodeId: {
    id: 'meter.search.advanced.nodeId',
    defaultMessage: 'Node ID',
  },
  firmwareVersion: {
    id: 'meter.search.advanced.firmwareVersion',
    defaultMessage: 'Firmware',
  },
  hardwareVersion: {
    id: 'meter.search.advanced.hardwareVersion',
    defaultMessage: 'Hardware',
  },
  driver: {
    id: 'meter.search.advanced.driver',
    defaultMessage: 'Driver',
  },
  gateway : {
    id: 'meter.search.advanced.gateway',
    defaultMessage: 'Gateway',
  },
   status : {
    id: 'meter.search.advanced.status',
    defaultMessage: 'Status',
  },
});

export const meterSearchCriteria = {
  [VENDOR]: {
    property: VENDOR,
    nullable: true,
    label: <FormattedMessage {...messages.vendor} />,
    type: PLAIN,
  },
  [FIRMVARE_VERSION]: {
    property: FIRMVARE_VERSION,
    nullable: false,
    label: <FormattedMessage {...messages.firmwareVersion} />,
    type: PLAIN,
  },
  [HARDWARE_VERSION]: {
    property: HARDWARE_VERSION,
    nullable: false,
    label: <FormattedMessage {...messages.hardwareVersion} />,
    type: PLAIN,
  },
   [GATEWAY]: {
    property: GATEWAY,
    nullable: false,
    label: <FormattedMessage {...messages.gateway} />,
    type: PLAIN,
  },
  [STATUS]: {
    property: STATUS,
    nullable: false,
    label: <FormattedMessage {...messages.status} />,
    type: types.CHOICE,
    singleConstraint: true,
    values: [
      {
        label: <FormattedMessage {...statusMessages[STATUS_ONLINE]} />,
        value: STATUS_ONLINE,
      },
      {
        label: <FormattedMessage {...statusMessages[STATUS_OFFLINE]} />,
        value: STATUS_OFFLINE,
      }],
  },
};

const MeterAdvancedSearch = ({
  ...props
}) => (
  <AdvancedSearch
    className="meter-advanced-search"
    criteriaConfig={S.values(meterSearchCriteria)}
    {...props}
  />
);

export const DisplaySearch = ({
  criteria,
}) => {
  const fullCriteria = S.map(
    criterion => ({
      ...meterSearchCriteria[S.prop('property', criterion)],
      constraints: criterion.constraints,
    }),
    criteria,
  );

  return S.map(
    criterion => (
      <DisplayCriteria
        label={S.maybeToNullable(S.map(
          S.prop('label'),
          S.find(S.compose(S.equals(criterion.property), S.prop('property')), meterSearchCriteria),
        ))}
        criteria={criterion}
      />
    ),
    fullCriteria,
  );
};

export default connect(
  state => ({
    hasCriteria: selectors.hasCriteria(state),
    getCriteriaState: selectors.getCriteriaState(state),
    hasConstraints: selectors.hasConstraints(state),
  }),
  actions,
)(MeterAdvancedSearch);
