import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { resolver } from 'found';
import { IntlProvider, injectIntl, addLocaleData } from 'react-intl';
import configureStore from './store/configure';
import configureServices from './service/configure';
import createConfig from './service/config';
import Router from './router/router';

const LANG = navigator.language.split('-')[0] || 'en';

let messages = {};
try {
  messages = require('../locale/en.json');
} catch (e) {}

addLocaleData(
  require(`react-intl/locale-data/${LANG}`),
);

(async () => {
  const smaConfig = await createConfig();

  ReactDOM.render((
    <IntlProvider locale={LANG} defaultLocale={LANG} messages={messages}>
      {React.createElement(injectIntl(({ intl }) => {
        const services = configureServices(intl, smaConfig);
        const store = configureStore(intl, services);

        return (
          <Provider store={store}>
            <Router
              matchContext={{
                intl,
                store,
                services,
              }}
              resolver={resolver}
            />
          </Provider>
        );
      }))}
    </IntlProvider>
  ), document.body.querySelector('.react-root'));
})();

